<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss">
    <div slot="no-body">
      <div class="justify-center items-center">
        <div class="d-theme-dark-bg" style="border-radius: 15px">


<p class="MsoNormal"><b><span  style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">{{$t('Hospitals')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span  style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span  style="font-size:14.0pt;line-height:115%;color:#00B050">{{$t('ListYourHospital')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span  style="font-size:14.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>

<p class="MsoNormal"><span  style="font-size:14.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('WillContactPatient')}}<o:p></o:p></span></p>

<p class="MsoNormal" align="center" style="text-align:center"><span  style="font-size:14.0pt;line-height:115%">&nbsp;</span></p>

<p class="MsoNormal" align="center" style="text-align:center"><span  style="font-size:14.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">{{$t('WhyDoclinia')}}<o:p></o:p></span></p>
<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('DocInfo1')}}</span></p>
<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('DocInfo2')}}</span></p>
<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('DocInfo3')}}</span></p>
<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('DocInfo4')}}</span></p>
<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('DocInfo5')}}&nbsp;</span></p>
<!-- <p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">Patients&rsquo; digital records&nbsp;</span></p> -->
<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('DocInfo6')}}</span></p>


 <video-player  class="video-player-box"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
 >
  </video-player>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%">&nbsp;</span></p>


<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%">&nbsp;</span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#4F81BD;mso-themecolor:accent1">{{$t('Signinto')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span ><a href="https://www.doclinia.com/pages/register/3"><b><span style="font-size:12.0pt;line-height:115%;color:#00B050">https://www.doclinia.com/pages/register/3</span></b></a></span><b><span  style="font-size:12.0pt;
line-height:115%;color:#00B050"><o:p></o:p></span></b></p>

<span  style="font-size:11.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;
mso-fareast-font-family:Arial;mso-ansi-language:EN;mso-fareast-language:EN-US;
mso-bidi-language:AR-SA"><br clear="all" style="mso-special-character:line-break;
page-break-before:always">
</span>



 </div>
    </div>
  </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';

export default {
  data() {
    return {
        playerOptions: {
          // videojs options
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: require("../assets/DocliniaDemoVideo.mp4")
          }],
          poster: "/static/images/author.jpg",
        },
    };
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {
    videoPlayer
  },
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #2753D9;
}
</style>


